import { USER_TYPE } from "shared/constants";
import api from "./api";

class itemApi {
  static getSuppliersAndFactories(data) {
    return api.get("/buyer/supplier_companies", data);
  }

  static createItem(data) {
    return api.post("/buyer/master_wics/tasks", data);
  }

  static getAddTaskDetails(data) {
    return api.get(`/buyer/master_wics/${data.id}/add_task_details`);
  }

  static createTasks(data) {
    return api.post("/buyer/tasks", data);
  }

  static createTaskRequirements(data) {
    return api.post("/buyer/task_requirements", data);
  }

  static editTaskRequirements(data) {
    return api.put("/buyer/task_requirements", data);
  }

  static addTasks({ data, type = USER_TYPE.supplier }) {
    if (type === USER_TYPE.supplier) {
      return api.post("/supplier/tasks/add_task", data);
    } else if (type === USER_TYPE.buyer) {
      return api.post("/buyer/tasks/add_task", data);
    }
  }

  static getItemListHeader({ data, type = USER_TYPE.supplier }) {
    if (type === USER_TYPE.supplier) {
      return api.get("/supplier/items/master_wic/header", {});
    } else if (type === USER_TYPE.buyer) {
      return api.get("/buyer/items/master_wic/header", {});
    }
  }

  static getItemListData({ data, type = USER_TYPE.supplier }) {
    if (type === USER_TYPE.supplier) {
      return api.post("/supplier/items/master_wic", data);
    } else if (type === USER_TYPE.buyer) {
      return api.post("/buyer/items/master_wic", data);
    }
  }

  static getAcknowledgeDetails(data) {
    return api.get(`/supplier/acknowledge/${data.id}`, {});
  }

  static acknowledgeItem(data) {
    return api.put("/supplier/acknowledge", data);
  }

  static getItemDetails({ data, type = USER_TYPE.supplier }) {
    if (type === USER_TYPE.supplier) {
      return api.get(`/supplier/items/master_wic/${data.id}`, {});
    } else if (type === USER_TYPE.buyer) {
      return api.get(`/buyer/items/master_wic/${data.id}`, {});
    }
  }

  static getItemDetailsTaskList({ data, type = USER_TYPE.supplier }) {
    if (type === USER_TYPE.supplier) {
      return api.get(`/supplier/items/master_wic/${data.id}/tasks`, {});
    } else if (type === USER_TYPE.buyer) {
      return api.get(`/buyer/items/master_wic/${data.id}/tasks`, {});
    }
  }

  static getItemDetailsTaskDetail({ data, type = USER_TYPE.supplier }) {
    if (type === USER_TYPE.supplier) {
      return api.get(
        `/supplier/items/master_wic/${data.id}/task_requirements/${data.taskRequirementId}`,
        {},
      );
    } else if (type === USER_TYPE.buyer) {
      return api.get(
        `/buyer/items/master_wic/${data.id}/task_requirements/${data.taskRequirementId}`,
        {},
      );
    }
  }

  static getItemDetailsTaskDetailQuotations({ data, type = USER_TYPE.supplier }) {
    if (type === USER_TYPE.supplier) {
      return api.get(`/supplier/items/master_wic/${data.id}/tasks/${data.taskId}/quotations`, {});
    } else if (type === USER_TYPE.buyer) {
      return api.get(`/buyer/items/master_wic/${data.id}/tasks/${data.taskId}/quotations`, {});
    }
  }

  static getItemDetailsTaskDetailReportsDetails({ data, type = USER_TYPE.supplier }) {
    if (type === USER_TYPE.supplier) {
      return api.get(
        `/supplier/items/master_wic/${data.id}/tasks/${data.taskId}/reports_and_files`,
        {},
      );
    } else if (type === USER_TYPE.buyer) {
      return api.get(
        `/buyer/items/master_wic/${data.id}/tasks/${data.taskId}/reports_and_files`,
        {},
      );
    }
  }

  static getItemDetailsTaskDetailInvoice({ data, type = USER_TYPE.supplier }) {
    if (type === USER_TYPE.supplier) {
      return api.get(`/supplier/items/master_wic/${data.id}/tasks/${data.taskId}/invoice`, {});
    } else if (type === USER_TYPE.buyer) {
      return api.get(`/buyer/items/master_wic/${data.id}/tasks/${data.taskId}/invoice`, {});
    }
  }

  static getItemDetailsProducts({ data, type = USER_TYPE.supplier }) {
    if (type === USER_TYPE.supplier) {
      return api.get(`/supplier/items/master_wic/${data.id}/products`, {});
    } else if (type === USER_TYPE.buyer) {
      return api.get(`/buyer/items/master_wic/${data.id}/products`, {});
    }
  }

  static getItemDetailsSupplierFactory({ data, type = USER_TYPE.supplier }) {
    if (type === USER_TYPE.supplier) {
      return api.get(`/supplier/items/master_wic/${data.id}/suppliers_and_factory`, {});
    } else if (type === USER_TYPE.buyer) {
      return api.get(`/buyer/items/master_wic/${data.id}/suppliers_and_factory`, {});
    }
  }

  static updateItemDetailsSupplierFactory({
    data: { masterWicId, data },
    type = USER_TYPE.supplier,
  }) {
    if (type === USER_TYPE.supplier) {
      return api.put(`/supplier/items/master_wic/${masterWicId}/suppliers_and_factory`, data);
    } else if (type === USER_TYPE.buyer) {
      return api.put(`/buyer/items/master_wic/${masterWicId}/suppliers_and_factory`, data);
    }
  }

  static getItemDetailsComments(data) {
    return api.get(`/buyer/items/master_wic/${data.id}/comments`);
  }

  // message: "this is message body"
  static setItemDetailsComments(data) {
    return api.post(`/buyer/items/master_wic/${data.id}/comments`, {
      message: data.value,
    });
  }

  static getMasterWicDetails(data) {
    return api.get(`/buyer/master_wics/${data.itemId}/master_wic_detail`, data);
  }

  static editItem(data) {
    return api.put(`/buyer/master_wics/tasks`, data);
  }

  static editTasks(data) {
    return api.put(`/buyer/tasks`, data);
  }

  static bulkEditItems(data) {
    return api.put(`/buyer/master_wics/bulk`, data);
  }

  static bulkAddItemsTask(data) {
    return api.post(`/buyer/master_wics/bulk`, data);
  }

  static bulkDeleteItemsTask(data) {
    return api.delete(`/buyer/master_wics/bulk`, data);
  }

  static createCustomHeader(data) {
    return api.post("/users/custom_header", data);
  }

  static exportItemListCsv(data) {
    return api.downloadWithData("/buyer/items/master_wic/csv", data);
  }

  static deleteCustomHeader(data) {
    return api.delete(`/users/custom_header/${data.headerId}`, data);
  }

  static updateCustomHeader(data) {
    return api.put("/users/custom_header", data);
  }

  static bulkEditPlannedDate(data) {
    return api.put("/supplier/master_wics/bulk", data);
  }

  static getCategoryPrograms(data) {
    return api.get("/common_data/programs?buyerCompanyTeam=all", data);
  }

  static getCategoryEverydayPrograms(data) {
    return api.get("/common_data/programs?buyerCompanyTeam=everyday", data);
  }

  static getCategorySeasonalPrograms(data) {
    return api.get("/common_data/programs?buyerCompanyTeam=seasonal", data);
  }

  static downloadProductSpec(data) {
    return api.downloadWithData("/buyer/download/items/download_product_spec", data);
  }

  static downloadAllReports(data) {
    return api.downloadWithData(`/buyer/download/items/${data.masterWicId}/download_reports`, {});
  }

  static updateIcl(data) {
    return api.put(`/buyer/master_wics/${data.id}/icl`, data);
  }

  static exportItemListExcel(data) {
    return api.downloadWithData("/buyer/master_wics/export_items_excel", data);
  }

  static deleteTask(data) {
    return api.delete(`/buyer/tasks/${data.taskID}`, data);
  }

  static bulkEditProgram777(data) {
    return api.put(`/buyer/items/master_wic/program777`, data);
  }

  static updateProgram777(data) {
    return api.put(
      `/buyer/items/master_wic/${data.id}/program777?isProgram777=${data.isProgram777}`,
    );
  }
}

export default itemApi;
