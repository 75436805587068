import moment from "moment";

export const formatHeader = (headerData = [], t, filter = {}) => {
  let formatedHeader = [];
  let filterItems = [];
  const sortingOptions = ["none", "ascending", "descending"];

  for (let i = 0; i < headerData.length; i++) {
    filterItems = [];

    if (filter[headerData[i].name]) {
      let tempFilterItems = filter[headerData[i].name].split("$$");

      for (let j = 0; j < tempFilterItems.length; j++) {
        if (tempFilterItems[j] && tempFilterItems[j] !== "empty_task") {
          filterItems.push({
            value: tempFilterItems[j],
            label:
              headerData[i].name.indexOf("Status") >= 0
                ? t(`taskStatus.${tempFilterItems[j]}`)
                : tempFilterItems[j],
          });
        }
      }
    }

    // if (headerData[i].filterItems) {
    //   if (headerData[i].name.indexOf("Status") >= 0) {
    //     for (let j = 0; j < headerData[i].filterItems.length; j++) {
    //       if (headerData[i].filterItems[j] && headerData[i].filterItems[j].name) {
    //         filterItems.push({
    //           value: headerData[i].filterItems[j].name,
    //           label: t(`taskStatus.${headerData[i].filterItems[j].name}`),
    //         });
    //       }
    //     }
    //   } else {
    //     for (let j = 0; j < headerData[i].filterItems.length; j++) {
    //       if (headerData[i].filterItems[j] && headerData[i].filterItems[j].name) {
    //         filterItems.push({
    //           value: headerData[i].filterItems[j].name,
    //           label: headerData[i].filterItems[j].name,
    //         });
    //       }
    //     }
    //   }
    // }

    if (headerData[i].name === "productName") {
      formatedHeader.push({
        name: headerData[i].name,
        label: "Product Name",
        hasFilter: false,
        filterItems: filterItems,
        selectedFilter: [],
        hasSorting: false,
        sortingOptions: [],
        sorting: 0,
      });
    } else if (headerData[i].name === "programName") {
      formatedHeader.push({
        name: headerData[i].name,
        label: "Program / Category",
        hasFilter: true,
        filterItems: filterItems,
        selectedFilter: [],
        hasSorting: true,
        sortingOptions: [],
        sorting: 0,
      });
    } else if (headerData[i].name === "brand") {
      formatedHeader.push({
        name: headerData[i].name,
        label: "Brand",
        hasFilter: true,
        filterItems: filterItems,
        selectedFilter: [],
        hasSorting: false,
        sortingOptions: [],
        sorting: 0,
      });
    } else if (headerData[i].name === "supplier") {
      formatedHeader.push({
        name: headerData[i].name,
        label: "Supplier",
        hasFilter: true,
        filterItems: filterItems,
        selectedFilter: [],
        hasSorting: false,
        sortingOptions: [],
        sorting: 0,
      });
    } else if (headerData[i].name.indexOf("Status") >= 0) {
      formatedHeader.push({
        name: headerData[i].name,
        label: t(`tableHeader.${headerData[i].name}`),
        hasFilter: true,
        filterItems: filterItems,
        selectedFilter: [],
        hasSorting: false,
        sortingOptions: [],
        sorting: 0,
      });
    } else if (headerData[i].name === "shipByDate") {
      formatedHeader.push({
        name: headerData[i].name,
        label: "Ship By Date",
        hasFilter: false,
        filterItems: filterItems,
        selectedFilter: [],
        hasSorting: true,
        sortingOptions: sortingOptions,
        sorting: 0,
      });
    } else if (headerData[i].name.indexOf("WbaDeadline") >= 0) {
      formatedHeader.push({
        name: headerData[i].name,
        label: t(`tableHeader.${headerData[i].name}`),
        hasFilter: false,
        filterItems: filterItems,
        selectedFilter: [],
        hasSorting: true,
        sortingOptions: sortingOptions,
        sorting: 0,
      });
    } else if (headerData[i].name.indexOf("Ecd") >= 0) {
      formatedHeader.push({
        name: headerData[i].name,
        label: t(`tableHeader.${headerData[i].name}`),
        hasFilter: false,
        filterItems: filterItems,
        selectedFilter: [],
        hasSorting: true,
        sortingOptions: sortingOptions,
        sorting: 0,
      });
    } else if (headerData[i].name === "pacValidityDate") {
      formatedHeader.push({
        name: headerData[i].name,
        label: t(`tableHeader.${headerData[i].name}`),
        hasFilter: false,
        filterItems: filterItems,
        selectedFilter: [],
        hasSorting: true,
        sortingOptions: sortingOptions,
        sorting: 0,
      });
    } else if (headerData[i].name === "validityDate") {
      formatedHeader.push({
        name: headerData[i].name,
        label: t(`tableHeader.${headerData[i].name}`),
        hasFilter: false,
        filterItems: filterItems,
        selectedFilter: [],
        hasSorting: true,
        sortingOptions: sortingOptions,
        sorting: 0,
      });
    } else if (headerData[i].name === "selfInspectionValidityDate") {
      formatedHeader.push({
        name: headerData[i].name,
        label: t(`tableHeader.${headerData[i].name}`),
        hasFilter: false,
        filterItems: filterItems,
        selectedFilter: [],
        hasSorting: true,
        sortingOptions: sortingOptions,
        sorting: 0,
      });
    } else {
      formatedHeader.push({
        name: headerData[i].name,
        label: t(`tableHeader.${headerData[i].name}`),
        hasFilter: filterItems.length > 0,
        filterItems: filterItems,
        selectedFilter: [],
        hasSorting: false,
        sortingOptions: [],
        sorting: 0,
      });
    }
  }

  return formatedHeader;
};

export const updateHeader = (headerData = [], filter = {}, t) => {
  let tempHeader = headerData;
  let filterItems = [];

  for (let i = 0; i < tempHeader.length; i++) {
    filterItems = [];

    if (filter[tempHeader[i].name]) {
      let tempFilterItems = filter[tempHeader[i].name].split("$$");

      for (let j = 0; j < tempFilterItems.length; j++) {
        if (tempFilterItems[j] && tempFilterItems[j] !== "empty_task") {
          filterItems.push({
            value: tempFilterItems[j],
            label:
              tempHeader[i].name.indexOf("Status") >= 0
                ? t(`taskStatus.${tempFilterItems[j]}`)
                : tempFilterItems[j],
          });
        }
      }
    }

    tempHeader[i].filterItems = filterItems;
  }

  return tempHeader;
};

export const formatRow = (headerData = [], rowData = [], t) => {
  let formatedRows = [];
  let data = [];
  let subWICs = [];
  let subWICData = [];

  for (let i = 0; i < rowData.length; i++) {
    data = [];
    subWICs = [];

    for (let j = 0; j < headerData.length; j++) {
      if (headerData[j].name === "productName") {
        data.push({
          name: headerData[j].name,
          value: rowData[i].wicNumber,
          info: rowData[i][headerData[j].name],
          hasImageButton: rowData[i].masterWicImages.length > 0,
          images: rowData[i].masterWicImages,
          isProgram777: rowData[i]?.isProgram777 || false,
        });
      } else if (headerData[j].name === "programName") {
        data.push({
          name: headerData[j].name,
          value:
            rowData[i].category === "everyday"
              ? rowData[i].programName
              : rowData[i].programName + " " + rowData[i].programYear,
          info: "",
          buyer: rowData[i].buyerCompanyName,
          hasImageButton: false,
          images: [],
          isTenderingProgram: rowData[i]?.isTenderingProgram || false,
          tenderingProgram: rowData[i]?.tenderingProgram || {},
        });
      } else if (
        headerData[j].name === "shipByDate" ||
        headerData[j].name === "pacValidityDate" ||
        headerData[j].name === "validityDate" ||
        headerData[j].name === "selfInspectionValidityDate"
      ) {
        data.push({
          name: headerData[j].name,
          value: rowData[i][headerData[j].name]
            ? moment(rowData[i][headerData[j].name]).format("DD/MMM/YYYY")
            : "N/A",
          info: "",
          hasImageButton: false,
          images: [],
        });
      } else if (headerData[j].name === "validityPeriod") {
        data.push({
          name: headerData[j].name,
          value: rowData[i].validityPeriod ? rowData[i].validityPeriod + " months" : "",
          info: "",
          hasImageButton: false,
          images: [],
        });
      } else if (headerData[j].name.indexOf("Status") >= 0) {
        let tooltipDetailsKey = headerData[j].name + "Details";
        const tooltipDetailsObject = rowData[i][tooltipDetailsKey];
        let tooltipDetails = [];

        if (tooltipDetailsObject) {
          Object.keys(tooltipDetailsObject).forEach((key) => {
            if (tooltipDetailsObject[key] !== 0) {
              tooltipDetails.push(t(`tooltipKey.${key}`) + " (" + tooltipDetailsObject[key] + ")");
            }
          });
        }

        data.push({
          name: headerData[j].name,
          value: rowData[i][headerData[j].name],
          info: "",
          hasImageButton: false,
          images: [],
          tooltipDetails: tooltipDetails,
        });
      } else if (headerData[j].name.indexOf("WbaDeadline") >= 0) {
        data.push({
          name: headerData[j].name,
          value: rowData[i][headerData[j].name]
            ? moment(rowData[i][headerData[j].name]).format("DD/MMM/YYYY")
            : "N/A",
          info: "",
          hasImageButton: false,
          images: [],
        });
      } else if (headerData[j].name.indexOf("Ecd") >= 0) {
        data.push({
          name: headerData[j].name,
          value: rowData[i][headerData[j].name]
            ? moment(rowData[i][headerData[j].name]).format("DD/MMM/YYYY")
            : "N/A",
          info: "",
          hasImageButton: false,
          images: [],
        });
      } else if (headerData[j].name === "factory") {
        data.push({
          name: headerData[j].name,
          value: rowData[i].factoryName || "N/A",
          info:
            (rowData[i].factoryCountry || "N/A") +
            "/" +
            (rowData[i].factoryState || "N/A") +
            "/" +
            (rowData[i].factoryCity || "N/A"),
          hasImageButton: false,
          images: [],
        });
      } else {
        data.push({
          name: headerData[j].name,
          value: rowData[i][headerData[j].name],
          info: "",
          hasImageButton: false,
          images: [],
        });
      }
    }

    for (let j = 0; j < rowData[i].subWics.length; j++) {
      subWICData = [];

      for (let k = 0; k < headerData.length; k++) {
        if (headerData[k].name === "productName") {
          subWICData.push({
            name: headerData[k].name,
            value: rowData[i].subWics[j].wicNumber,
            info: rowData[i].subWics[j][headerData[k].name],
            hasImageButton: false,
            images: [],
          });
        } else if (headerData[k].name === "shipByDate") {
          subWICData.push({
            name: headerData[k].name,
            value: rowData[i].subWics[j][headerData[k].name]
              ? moment(rowData[i].subWics[j][headerData[k].name]).format("DD/MMM/YYYY")
              : "N/A",
            info: "",
            hasImageButton: false,
            images: [],
          });
        } else if (headerData[k].name.indexOf("Status") >= 0) {
          subWICData.push({
            name: headerData[k].name,
            value: rowData[i].subWics[j][headerData[k].name],
            info: "",
            hasImageButton: false,
            images: [],
          });
        } else if (headerData[k].name.indexOf("WbaDeadline") >= 0) {
          subWICData.push({
            name: headerData[k].name,
            value: rowData[i].subWics[j][headerData[k].name]
              ? moment(rowData[i].subWics[j][headerData[k].name]).format("DD/MMM/YYYY")
              : "N/A",
            info: "",
            hasImageButton: false,
            images: [],
          });
        } else if (headerData[k].name.indexOf("Ecd") >= 0) {
          subWICData.push({
            name: headerData[k].name,
            value: rowData[i].subWics[j][headerData[k].name]
              ? moment(rowData[i].subWics[j][headerData[k].name]).format("DD/MMM/YYYY")
              : "N/A",
            info: "",
            hasImageButton: false,
            images: [],
          });
        } else {
          subWICData.push({
            name: headerData[k].name,
            value: rowData[i].subWics[j][headerData[k].name],
            info: "",
            hasImageButton: false,
            images: [],
          });
        }
      }

      subWICs.push({
        id: rowData[i].subWics[j].id,
        data: subWICData,
      });
    }

    formatedRows.push({
      id: rowData[i].id,
      data: data,
      subWICs: subWICs,
    });
  }

  return formatedRows;
};
