import React, { useState } from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import Program777Chip from "components/display/Program777Chip";
import TrafficLightWithTooltip from "components/display/TrafficLight/TrafficLightWithTooltip";
import TenderingChip from "components/display/TenderingChip";

const useStyles = makeStyles(() => ({
  label777box: {
    marginRight: 4,
  },
  label777: {
    backgroundColor: "#668BC9",
    color: "#fff",
    width: 24,
    height: 24,
    fontSize: 14,
    fontWeight: "bold",
  },
  tableRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#eeeeee",
    },
  },
  actionCell: {
    paddingLeft: "20px !important",
  },
  tableCell: {
    position: "relative",
    zIndex: 991,
    height: 40,
    minWidth: 110,
    // maxWidth: 220,
    padding: "0 10px",
    fontSize: 12,
    fontWeight: "normal",
    borderBottom: "1px solid #777777",
    borderRight: "1px solid #777777",
    "&:last-child": {
      borderRight: "none",
    },
  },
  expandedTableCell: {
    position: "relative",
    zIndex: 991,
    height: 40,
    minWidth: 110,
    padding: "0 10px",
    fontSize: 12,
    fontWeight: "normal",
    borderBottom: "none",
    borderRight: "1px solid #777777",
    "&:last-child": {
      borderRight: "none",
    },
  },
  subWICTableCell: {
    position: "relative",
    zIndex: 991,
    height: 40,
    minWidth: 110,
    // maxWidth: 220,
    padding: "0 10px 0 20px",
    fontSize: 12,
    fontWeight: "normal",
    borderBottom: "1px solid #777777",
    borderRight: "1px solid #777777",
    "&:last-child": {
      borderRight: "none",
    },
  },
  subWICExpandedTableCell: {
    position: "relative",
    zIndex: 991,
    height: 40,
    minWidth: 110,
    padding: "0 10px 0 20px",
    fontSize: 12,
    fontWeight: "normal",
    borderBottom: "none",
    borderRight: "1px solid #777777",
    "&:last-child": {
      borderRight: "none",
    },
  },
  tableText: {
    maxWidth: 170,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  subWICTableText: {
    maxWidth: 160,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  info: {
    fontSize: 10,
    color: "#777777",
  },
  buyer: {
    fontSize: 10,
    color: "#003DA5",
  },
  headerLabel: {
    float: "left",
  },
  filterContainer: {
    padding: 0,
  },
  filter: {
    color: "#000000",
  },
  sortingContainer: {
    position: "relative",
    paddingLeft: 2,
    cursor: "pointer",
  },
  sortingContainerWithMargin: {
    position: "relative",
    paddingLeft: 2,
    marginRight: 30,
    cursor: "pointer",
  },
  selected: {
    color: "#196EFF",
  },
  imageButtonContainer: {
    margin: "4px 0 0 0",
    padding: 0,
  },
  imageButton: {
    color: "#DADADA",
  },
  trafficLight: {
    textAlign: "center",
    paddingLeft: 5,
    paddingRight: 5,
    // paddingRight: 35,
    // "&:last-child": {
    //   paddingRight: 0,
    // },
  },
}));

const ItemListTableRow = ({
  itemId,
  checked,
  row = [],
  subWICs = [],
  handleItemSelect,
  handleViewImage,
  handleRowClick,
  cy,
  cyProductName,
}) => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);
  const [expandMore, setExpandMore] = useState(false);

  const handleExpandClick = () => {
    if (subWICs.length > 0) {
      if (!expanded) {
        setExpandMore(true);
      } else {
        setExpandMore(false);
      }
    } else {
      setExpandMore(false);
    }

    setExpanded(!expanded);
  };

  const isSelectedColumn = (itemName) => () => itemName === itemName;

  const isProductNameColumn = isSelectedColumn("productName");
  const isProgramNameColumn = isSelectedColumn("programName");

  const isProductNameAndShowProgram777 = (item) =>
    isProductNameColumn(item?.name) && item?.isProgram777;

  const isProgramNameAndShowTenderingProgram = (item) =>
    isProgramNameColumn(item?.name) && item?.isTenderingProgram;

  return (
    <>
      <TableRow
        className={classes.tableRow}
        data-cy-product-name={cyProductName}
        data-cy={`row-${cy}`}
      >
        <TableCell
          className={
            expandMore
              ? classnames(classes.expandedTableCell, classes.actionCell)
              : classnames(classes.tableCell, classes.actionCell)
          }
        >
          <IconButton size="small" onClick={() => handleExpandClick()}>
            {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>

          <Checkbox
            color="primary"
            checked={checked}
            onChange={(e) => handleItemSelect(e, itemId)}
            data-cy-checked={checked}
            data-cy={`row-checkbox-${cy}`}
          />
        </TableCell>

        {row.map((item) => {
          return item.name.indexOf("Status") >= 0 ? (
            <TableCell
              key={item.name}
              className={expandMore ? classes.expandedTableCell : classes.tableCell}
              onClick={() => handleRowClick(itemId)}
            >
              <Box className={classes.trafficLight}>
                <TrafficLightWithTooltip status={item.value} tooltipDetails={item.tooltipDetails} />
              </Box>
            </TableCell>
          ) : (
            <TableCell
              key={item.name}
              className={expandMore ? classes.expandedTableCell : classes.tableCell}
              onClick={() => (item.hasImageButton ? null : handleRowClick(itemId))}
            >
              {item.hasImageButton ? (
                <Box display="flex">
                  <Box flexGrow={1} display="flex" onClick={() => handleRowClick(itemId)}>
                    <Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        className={classnames(classes.tableText)}
                      >
                        {isProductNameAndShowProgram777(item) && <Program777Chip />}

                        {isProgramNameAndShowTenderingProgram(item) && (
                          <Box>
                            <TenderingChip detail={item?.tenderingProgram} />
                          </Box>
                        )}

                        {item.value}
                      </Box>

                      {item.info && (
                        <Box className={classnames(classes.info, classes.tableText)}>
                          {item.info}
                        </Box>
                      )}
                      {item.buyer && (
                        <Box className={classnames(classes.buyer, classes.tableText)}>
                          {item.buyer}
                        </Box>
                      )}
                    </Box>
                  </Box>

                  <Box display="flex">
                    <IconButton
                      className={classes.imageButtonContainer}
                      onClick={() => handleViewImage(item.images)}
                    >
                      <PhotoCameraIcon className={classes.imageButton} />
                    </IconButton>
                  </Box>
                </Box>
              ) : (
                <Box display="flex">
                  {isProductNameAndShowProgram777(item) && <Program777Chip />}

                  {isProgramNameAndShowTenderingProgram(item) && (
                    <Box display="flex" alignItems="flex-end" justifyContent="flex-end">
                      <TenderingChip detail={item?.tenderingProgram} />
                    </Box>
                  )}

                  <Box flexGrow={1} display="flex">
                    <Box>
                      <Box className={classes.tableText}>{item.value}</Box>

                      {item.info && (
                        <Box className={classnames(classes.info, classes.tableText)}>
                          {item.info}
                        </Box>
                      )}

                      {item.buyer && (
                        <Box className={classnames(classes.buyer, classes.tableText)}>
                          {item.buyer}
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              )}
            </TableCell>
          );
        })}
      </TableRow>

      {expandMore && (
        <>
          {subWICs.map((subWIC, index) => (
            <TableRow
              key={subWIC.id}
              className={classes.tableRow}
              onClick={() => handleRowClick(itemId)}
            >
              <TableCell
                className={
                  index !== subWICs.length - 1
                    ? classnames(classes.expandedTableCell, classes.actionCell)
                    : classnames(classes.tableCell, classes.actionCell)
                }
              />
              {subWIC.data.map((item) =>
                item.name.indexOf("Status") >= 0 ? (
                  <TableCell
                    key={item.name}
                    className={
                      index !== subWICs.length - 1 ? classes.expandedTableCell : classes.tableCell
                    }
                  >
                    <Box className={classes.trafficLight}>
                      <TrafficLightWithTooltip status={item.value} />
                    </Box>
                  </TableCell>
                ) : (
                  <TableCell
                    key={item.name}
                    className={
                      index !== subWICs.length - 1
                        ? classes.subWICExpandedTableCell
                        : classes.subWICTableCell
                    }
                  >
                    <Box display="flex">
                      <Box flexGrow={1} display="flex">
                        <Box>
                          <Box className={classes.subWICTableText}>{item.value}</Box>
                          {item.info && (
                            <Box className={classnames(classes.info, classes.subWICTableText)}>
                              {item.info}
                            </Box>
                          )}
                        </Box>
                      </Box>
                      <Box display="flex">
                        {item.hasImageButton && (
                          <IconButton
                            className={classes.imageButtonContainer}
                            onClick={() => handleViewImage(item.images)}
                          >
                            <PhotoCameraIcon className={classes.imageButton} />
                          </IconButton>
                        )}
                      </Box>
                    </Box>
                  </TableCell>
                ),
              )}
            </TableRow>
          ))}
        </>
      )}
    </>
  );
};

export default ItemListTableRow;
