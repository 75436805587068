import React, { memo } from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

import SearchableMultiSelect from "components/input/SearchableMultiSelect";
import ItemListTableRow from "./ItemListTableRow";
import Pagination from "../Pagination";

const useStyles = makeStyles(() => ({
  tableContainer: {
    position: "relative",
    // padding: "26px 30px",
    paddingBottom: 3,
    whiteSpace: "nowrap",
    minWidth: 600,
    minHeight: 300,
  },
  table: {
    position: "relative",
    zIndex: 995,
  },
  tableHeaderCell: {
    position: "relative",
    zIndex: 996,
    padding: "10px 13px 10px 10px",
    color: "#777777",
    fontSize: 12,
    fontWeight: "normal",
    borderTop: "1px solid #777777",
    borderBottom: "1px solid #777777",
    borderRight: "1px solid #777777",
    "&:last-child": {
      borderRight: "none",
    },
    minWidth: 100,
  },
  actionCell: {
    position: "relative",
    zIndex: 996,
    padding: "0px 13px 0px 50px",
    color: "#777777",
    fontSize: 12,
    fontWeight: "normal",
    borderTop: "1px solid #777777",
    borderBottom: "1px solid #777777",
    borderRight: "1px solid #777777",
    // minWidth: 100,
    width: 110,
  },
  tableText: {
    maxWidth: 170,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  headerLabel: {
    float: "left",
  },
  filterContainer: {
    padding: 0,
  },
  filter: {
    color: "#000000",
    width: 18,
    height: 18,
  },
  selectedFilter: {
    color: "#005EFF",
  },
  sortingContainer: {
    position: "relative",
    paddingLeft: 2,
    cursor: "pointer",
  },
  sortingContainerWithMargin: {
    position: "relative",
    paddingLeft: 2,
    marginRight: 30,
    cursor: "pointer",
  },
  sortingUp: {
    position: "absolute",
    top: 0,
    left: 0,
    width: 15,
    height: "auto",
  },
  sortingDown: {
    position: "absolute",
    top: 7,
    left: 0,
    width: 15,
    height: "auto",
  },
  selected: {
    color: "#196EFF",
  },
  trafficLightTitle: {
    paddingRight: 30,
    fontSize: 12,
    "&:last-child": {
      paddingRight: 0,
    },
  },
  filterMenuContainer: {
    position: "absolute",
    top: 40,
    right: -90,
  },
  lastFilterMenuContainer: {
    position: "absolute",
    top: 40,
    right: 0,
  },
  overlayFilter: {
    zIndex: -1,
    position: "fixed",
    inset: 0,
    backgroundColor: "transparent",
    "-webkit-tap-highlight-color": "transparent",
  },
  taskStatusHeader: {
    padding: 0,
    minWidth: "fit-content",
    color: "#777777",
    fontSize: 12,
    fontWeight: "normal",
  },
}));

const ItemListTable = ({
  tableTitle = "",
  headerData = [],
  rowData = [],
  selectedFilter = {},
  handleRowClick,
  hasTopButton,
  topButtonLabel,
  handleTopButtonClick,
  handleSorting,
  handleFilterListToggle,
  handleFilterItemSelect,
  tasks = [],

  isSelectedAll = false,
  unselectedItems = [],
  handleSelectAll,

  selectedItem = [],
  handleItemSelect,
  handleViewImage,
  taskList = [],
  currentPage,
  rowsPerPage,
  totalPages,
  totalRecords,
  rowsPerPageOption,
  handlePageChange,
  handleRowsPerPageChange,
  cy = "",
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Pagination
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
        totalPages={totalPages}
        totalRecords={totalRecords}
        rowsPerPageOption={rowsPerPageOption}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />

      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.actionCell}>
              <Checkbox
                color="primary"
                checked={isSelectedAll}
                onChange={(e) => handleSelectAll(e)}
              />
            </TableCell>
            {headerData.map((item, index) => (
              <TableCell key={item.name} className={classes.tableHeaderCell}>
                {/* {item.name.indexOf("Status") >= 0 ? (
                  item.label.map(label => (
                    <Typography key={label} className={classes.trafficLightTitle} variant="body1">
                      {item.label}
                    </Typography>
                  ))
                ) : ( */}
                <Box display="flex">
                  <Box flexGrow={1} display="flex" className={classes.headerLabel}>
                    <Box className={classes.tableText}>
                      {item.name.indexOf("Status") >= 0 ? (
                        <Tooltip arrow title={t(`task.${item.label}`)}>
                          <Button className={classes.taskStatusHeader}>{item.label}</Button>
                        </Tooltip>
                      ) : (
                        item.label
                      )}
                    </Box>

                    {item.hasSorting && (
                      <Box
                        display="flex"
                        className={
                          item.hasFilter
                            ? classes.sortingContainerWithMargin
                            : classes.sortingContainer
                        }
                        onClick={() => handleSorting(index, item.name, item.sorting)}
                      >
                        <ExpandLessIcon
                          className={classnames(classes.sortingUp, {
                            [classes.selected]: item.sorting === 1,
                          })}
                        />
                        <ExpandMoreIcon
                          className={classnames(classes.sortingDown, {
                            [classes.selected]: item.sorting === 2,
                          })}
                        />
                      </Box>
                    )}
                  </Box>
                  {item.hasFilter && (
                    <Box>
                      <IconButton
                        className={classes.filterContainer}
                        onClick={() => handleFilterListToggle(index)}
                      >
                        <FilterListIcon
                          className={classnames(classes.filter, {
                            [classes.selectedFilter]: selectedFilter[item.name].length > 0,
                          })}
                        />
                      </IconButton>

                      {item.openFilterList && (
                        <>
                          <Box
                            className={classes.overlayFilter}
                            onClick={() => handleFilterListToggle(index)}
                          ></Box>
                          <Box
                            className={
                              index === headerData.length - 1
                                ? classes.lastFilterMenuContainer
                                : classes.filterMenuContainer
                            }
                          >
                            <SearchableMultiSelect
                              filterName={item.name}
                              filterItems={item.filterItems}
                              selectedFilter={selectedFilter}
                              handleFilterItemSelect={handleFilterItemSelect}
                            />
                          </Box>
                        </>
                      )}
                    </Box>
                  )}
                </Box>
                {/* )} */}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody data-cy-count={rowData.length} data-cy={`${cy}-tbody`}>
          {rowData.map((row) => (
            <ItemListTableRow
              key={row.id}
              itemId={row.id}
              checked={
                (isSelectedAll && unselectedItems.indexOf(row.id) === -1) ||
                selectedItem.indexOf(row.id) >= 0
              }
              row={row.data}
              subWICs={row.subWICs}
              handleItemSelect={handleItemSelect}
              handleViewImage={handleViewImage}
              handleRowClick={handleRowClick}
              cy={row.id}
              cyProductName={row.data[0].value} //productName value
            />
          ))}
        </TableBody>
      </Table>

      <Pagination
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
        totalPages={totalPages}
        totalRecords={totalRecords}
        rowsPerPageOption={rowsPerPageOption}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />
    </TableContainer>
  );
};

export default memo(ItemListTable);
