import itemApi from "../api/itemApi";

export const atnGetSuppliersAndFactories = (reqData = {}) => {
	return itemApi.getSuppliersAndFactories(reqData);
};

export const atnCreateItem = (reqData = {}) => {
	return itemApi.createItem(reqData);
};

export const atnGetAddTaskDetails = (reqData = {}) => {
	return itemApi.getAddTaskDetails(reqData);
};

export const atnCreateTasks = (reqData = {}, isEverydayProgram = false) => {
	if (isEverydayProgram) {
		return itemApi.createTaskRequirements(reqData);
	}

	return itemApi.createTasks(reqData);
};

export const atnCreateTaskRequirements = (reqData = {}) => {
	return itemApi.createTaskRequirements(reqData);
};

export const atnAddTasks = (reqData = {}) => {
	return itemApi.addTasks(reqData);
};

export const atnGetItemListHeader = (reqData = {}) => {
	return itemApi.getItemListHeader(reqData);
};

export const atnGetItemListData = (reqData = {}) => {
	return itemApi.getItemListData(reqData);
};

export const atnGetAcknowledgeDetails = (reqData = {}) => {
	return itemApi.getAcknowledgeDetails(reqData);
};

export const atnAcknowledgeItem = (reqData = {}) => {
	return itemApi.acknowledgeItem(reqData);
};

export const atnGetItemDetails = (reqData = {}) => {
	return itemApi.getItemDetails(reqData);
};

export const atnGetItemDetailsTaskList = (reqData = {}) => {
	return itemApi.getItemDetailsTaskList(reqData);
};

export const atnGetItemDetailsTaskDetail = (reqData = {}) => {
	return itemApi.getItemDetailsTaskDetail(reqData);
};

export const atnGetItemDetailsTaskDetailQuotations = (reqData = {}) => {
	return itemApi.getItemDetailsTaskDetailQuotations(reqData, {});
};

export const atnGetItemDetailsTaskDetailReportsDetails = (reqData = {}) => {
	return itemApi.getItemDetailsTaskDetailReportsDetails(reqData, {});
};

export const atnGetItemDetailsTaskDetailInvoice = (reqData = {}) => {
	return itemApi.getItemDetailsTaskDetailInvoice(reqData, {});
};

export const atnGetItemDetailsProducts = (reqData = {}) => {
	return itemApi.getItemDetailsProducts(reqData);
};

export const atnGetItemDetailsSupplierFactory = (reqData = {}) => {
	return itemApi.getItemDetailsSupplierFactory(reqData);
};

export const atnUpdateItemDetailsSupplierFactory = (reqData = {}) => {
	return itemApi.updateItemDetailsSupplierFactory(reqData);
};

export const atnGetItemDetailsComments = (reqData = {}) => {
	return itemApi.getItemDetailsComments(reqData);
};

export const atnSetItemDetailsComments = (reqData = {}) => {
	return itemApi.setItemDetailsComments(reqData);
};

export const atnGetMasterWicDetails = (reqData = {}) => {
	return itemApi.getMasterWicDetails(reqData);
};

export const atnEditItem = (reqData = {}) => {
	return itemApi.editItem(reqData);
};

export const atnEditTasks = (reqData = {}, isEverydayProgram = false) => {
	if (isEverydayProgram) {
		return itemApi.editTaskRequirements(reqData);
	}

	return itemApi.editTasks(reqData);
};

export const atnBulkEditItems = (reqData = {}) => {
	return itemApi.bulkEditItems(reqData);
};

export const atnBulkAddItemsTask = (reqData = {}) => {
	return itemApi.bulkAddItemsTask(reqData);
};

export const atnBulkDeleteItemsTask = (reqData = {}) => {
	return itemApi.bulkDeleteItemsTask(reqData);
};

export const atnCreateCustomHeader = (reqData = {}) => {
	return itemApi.createCustomHeader(reqData);
};

export const atnExportItemListCsv = (reqData = {}) => {
	return itemApi.exportItemListCsv(reqData);
};

export const atnDeleteCustomHeader = (reqData = {}) => {
	return itemApi.deleteCustomHeader(reqData);
};

export const atnUpdateCustomHeader = (reqData = {}) => {
	return itemApi.updateCustomHeader(reqData);
};

export const atnBulkEditPlannedDate = (reqData = {}) => {
	return itemApi.bulkEditPlannedDate(reqData);
};

export const atnGetCategoryPrograms = (reqData = {}) => {
	return itemApi.getCategoryPrograms(reqData);
};

export const atnGetCategoryEverydayPrograms = (reqData = {}) => {
	return itemApi.getCategoryEverydayPrograms(reqData);
};

export const atnGetCategorySeasonalPrograms = (reqData = {}) => {
	return itemApi.getCategorySeasonalPrograms(reqData);
};

export const atnDownloadProductSpec = (reqData = {}) => {
	return itemApi.downloadProductSpec(reqData);
};

export const atnDownloadAllReports = (reqData = {}) => {
	return itemApi.downloadAllReports(reqData);
};

export const atnUpdateIcl = (reqData = {}) => {
	return itemApi.updateIcl(reqData);
};

export const atnExportItemListExcel = (reqData = {}) => {
	return itemApi.exportItemListExcel(reqData);
};

export const atnDeleteTask = (reqData = {}) => {
	return itemApi.deleteTask(reqData);
};

export const atnBulkEditProgram777 = (reqData = {}) => {
	return itemApi.bulkEditProgram777(reqData);
}

export const atnUpdateProgram777 = (reqData = {}) => {
	return itemApi.updateProgram777(reqData);
}
